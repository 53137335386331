const getBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("playpen") || hostname.includes("localhost")) {
    // return process.env.REACT_APP_TEST_URL;
    return process.env.REACT_APP_TEST_URL;
  } else {
    return process.env.REACT_APP_BASE_URL;
  }
};
const URL = getBaseUrl();
export default URL;

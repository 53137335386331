import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { api } from "../../../../../../api";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { AssessementContext } from "../../../AssessmentContext";
import { toast } from "react-hot-toast";
import BookexamModal from "./BookexamModal";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { updatedSavedAnswerPayload } from "../../candidate-assessment/components/utils/transformSavedAnswerFormat";
import { flushSubmittedAnswers } from "../../../../../../features/candidate-exam/candidate-exam-slice";
import { toPlainAnwers } from "../../candidate-assessment/components/utils/functions";
//import { flushSubmittedAnswers } from "../../../../../features/candidate-exam/candidate-exam-slice";
import UnlockAssessmentModal from "./unlockAssessmentModal";

const UpcommingAssessment = () => {
  const formatTo12Hour = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    // Format minutes and seconds to always be two digits
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
    // Construct the formatted time string
    const formattedTime = `${hours}:${minutesStr}:${secondsStr} ${ampm}`;
    return formattedTime;
  };

  const [type, setType] = useState("virtual");

  const { assessment, setAssessment } = useContext(AssessementContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [hasExams, setHasExams] = useState(true);
  const [examLocked, setExamLocked] = useState(false);
  const [viewBookingModal, setViewBookingModal] = useState(false);
  const dispatch = useDispatch();
  const { questionSavedAnswers, questionsDuration, examQuestions } =
    useSelector((state) => state.candidateExam);
  const last_assessment_id = sessionStorage.getItem("last_asessement_id");
  const last_asessement_user_group_id = sessionStorage.getItem(
    "last_asessement_user_group_id"
  );

  const mutation = useMutation(api.getUserGroupAssesment, {
    onSuccess: (data) => {
      setAssessment({
        ...assessment,
        assessements: { ...data?.data },
        activeQuestion: { ...data?.data?.questions[0] },
        questionType: data?.data?.questions[0]?.type,
        activeAssessment: {
          ...JSON.parse(window.sessionStorage.getItem("active-assessment")),
        },
      });
    },

    onError: (errorMessage) => {
      if (errorMessage.toString().includes("400")) {
        toast.error("Assessment needs to be unlocked by OTP or and admin");
      }
    },
  });

  const {
    isLoading,
    data: data,
    refetch,
    isError,
    error,
  } = useQuery(
    ["user-group-assessment", type],
    async () => {
      const data = await api.getAssesments({ type });
      return data;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.upcoming_exams?.length) {
          setHasExams(false);
        }
        setAssessment({ ...assessment, viewAsessments: { ...data?.data } });
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const updateAnswersMutation = useMutation(api.updateAnswers, {
    onSuccess: (response) => {
      dispatch(flushSubmittedAnswers());
      refetch();
    },
  });

  const updateAnswers = (answers, questions) => {
    updateAnswersMutation.mutate({
      user_group_id: last_asessement_user_group_id,
      assessment_id: last_assessment_id,
      data: {
        ...updatedSavedAnswerPayload(
          [
            ...answers.map((answer) => {
              return {
                duration: answer?.duration,
                answer_id: answer?.answer_id,
                question_id: answer?.question_id,
                answer_text: answer?.answer_text,
              };
            }),
          ],
          questionsDuration,
          questions
        ),
        time_left: parseInt(sessionStorage.getItem("client_side_time_left")),
      },
    });
  };

  const getQuestionsmutation = useMutation(api.getExamQuestions, {
    onSuccess: (data) => {
      //compare between current question and prevoius saved questions
      const questions = data?.data?.questions?.filter((q) => {
        return q?.answers?.some((ans) => ans?.status === true);
      });
      if (examQuestions?.length) {
        if (questions?.length === 0) {
          //sync client answers
          const client_answers = toPlainAnwers(examQuestions);
          updateAnswers(client_answers, data?.data?.questions);
        } else {
          //check for unsync questions and retry submitting
          const client_answers = toPlainAnwers(examQuestions);
          const server_answers = toPlainAnwers(questions);
          const unsynced_answers = client_answers.filter((client_answer) => {
            return !server_answers.some((server_answer) => {
              return (
                client_answer.question_id === server_answer.question_id &&
                client_answer.answer_id === server_answer.answer_id
              );
            });
          });
          //update answers
          if (unsynced_answers?.length)
            updateAnswers(unsynced_answers, data?.data?.questions);
        }
      }
    },
  });

  React.useEffect(() => {
    //update unsubmitted answers to sync with the backend.
    if (last_assessment_id) {
      getQuestionsmutation.mutate({
        assessmentId: last_assessment_id,
        groupId: last_asessement_user_group_id,
      });
    }
  }, []);

  useEffect(() => {
    const contactButton = document.querySelector(
      ".woot-widget-bubble.woot-elements--right"
    );
    //-------- hide and contact us button when exam is taken ----------------------//
    if (contactButton) {
      if (location.pathname.includes("candidate-assessment")) {
        contactButton.style.visibility = "hidden";
      } else {
        contactButton.style.visibility = "visible";
      }
    }
  }, [location.pathname]);

  const handleUserId = (exam) => {
    sessionStorage.setItem("user_group_id", exam.user_group_id);
    sessionStorage.setItem("group_id", exam.group_id);
    sessionStorage.setItem("active-candidate-exam", JSON.stringify(exam));
    sessionStorage.setItem("exam-type", "normal");
    if (
      exam?.assessments?.length &&
      exam?.assessments[0]?.assessment_lock_status === 0
    ) {
      setExamLocked(true);
    } else {
      // if (exam?.require_booking && exam?.booking_status === 1) {
      if (type === "physical") {
        window.location.href =
          "https://candidate.speedexam.net/signin.aspx?site=setassessment";
      } else {
        navigate(`/candidate/assessments/candidate-exams/${exam?.group_id}`);
      }
    }
  };

  const handleClickExam = (exam) => {
    sessionStorage.setItem("group_id", exam.group_id);
    sessionStorage.setItem("user_group_id", exam.user_group_id);
    sessionStorage.setItem("active-candidate-exam", JSON.stringify(exam));
    sessionStorage.setItem("exam-type", "normal");
    setViewBookingModal(true);
  };

  return (
    <>
      {" "}
      {examLocked && (
        <UnlockAssessmentModal
          closeModal={setExamLocked}
          refetch={refetch}
          // getExamsMutation={getExamsMutation}
          // id={id}
        />
      )}
      {mutation.isLoading || isLoading ? (
        <div className="w-[100vw] h-[100vh] fixed top-0 left-0 bg-[rgba(0,0,0,0.05)] block z-[1000]">
          <div className="w-full h-full flex items-center justify-center">
            <span className="px-5 py-3 bg-white">
              <ScaleLoader
                size={170}
                color={"rgb(15, 212, 15)"}
                loading={true}
                className="m-0 p-0"
              />
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-center mt-5">
            <div className="bg-slate-200 flex items-center justify-center gap-2 p-2 rounded-md">
              <button
                onClick={() => setType("virtual")}
                className={`text-xl py-2 px-6 rounded-md ${
                  type === "virtual"
                    ? "text-white bg-slate-400"
                    : "hover:bg-slate-200"
                }`}
              >
                Online Exam
              </button>
              <button
                onClick={() => setType("physical")}
                className={`text-xl py-2 px-6 rounded-md ${
                  type === "physical"
                    ? "text-white bg-slate-400"
                    : "hover:bg-slate-200"
                }`}
              >
                Assessment Day
              </button>
            </div>
          </div>
          <div className="text-white bg-darkBlue my-4 py-5 px-4 mx-1 rounded-lg">
            Upcoming Exams &nbsp;{" "}
            {assessment?.viewAsessments?.upcoming_exams?.length
              ? `(${assessment?.viewAsessments?.upcoming_exams?.length})`
              : null}
          </div>

          {!isLoading && assessment?.viewAsessments?.upcoming_exams?.length ? (
            <>
              {viewBookingModal && (
                <BookexamModal
                  refetch={refetch}
                  // getAssessmentMutation={getAssessmentMutation}
                  closeModal={setViewBookingModal}
                />
              )}
              {/** for mobile devices */}
              <div className="md:grid grid-cols-2 gap-8 md:px-2">
                {assessment?.viewAsessments?.upcoming_exams?.length
                  ? assessment?.viewAsessments?.upcoming_exams?.map(
                      (exam, id) => {
                        return (
                          <ul
                            key={id}
                            className="border bg-white shadow rounded-xl mb-2 p-0"
                          >
                            <li className="flex justify-between border-b border-gray-300 py-5 px-4">
                              <span className="text-md">Group Name:&nbsp;</span>
                              <span className="text-md">
                                {exam?.group_name}
                              </span>
                            </li>
                            <li className="flex border-b border-gray-300 justify-between py-4 px-4">
                              <span className="text-md">
                                Client Name:&nbsp;
                              </span>
                              <span className="text-md">
                                {exam?.client_name}
                              </span>
                            </li>
                            <li className="flex justify-between border-b border-gray-300 py-4 px-4">
                              <div>
                                <span className="text-md">
                                  Assessments:&nbsp;
                                </span>
                                <span className="text-md">
                                  {exam?.number_of_assessments}
                                </span>
                              </div>
                              {exam?.booking_status === 1 ? (
                                <div>
                                  <span className="text-md">OTP:&nbsp;</span>
                                  <span className="text-md text-blue-500">
                                    {exam?.otp}
                                  </span>
                                </div>
                              ) : null}
                            </li>
                            <li className="md:flex justify-between border-b border-gray-300 py-4 px-4">
                              {exam?.require_booking &&
                              exam?.booking_status === 1 ? (
                                <div>
                                  <span className="text-md">
                                    Booked Date:&nbsp;
                                  </span>
                                  <span className="text-md">
                                    {`${new Date(
                                      exam?.booked_date
                                    ).toLocaleDateString("en-ng")}`}
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <span className="text-md">
                                    Start Date:&nbsp;
                                  </span>
                                  <span className="text-md">
                                    {`${new Date(
                                      exam?.start_date
                                    ).toLocaleDateString("en-ng")}`}
                                    &nbsp;
                                    {formatTo12Hour(
                                      new Date(
                                        `${exam?.start_date} ${exam?.start_time}`
                                      )
                                    )}
                                  </span>
                                </div>
                              )}
                              {exam?.require_booking &&
                              exam?.booking_status === 1 ? (
                                <div>
                                  <span className="text-md">
                                    Booked Time:&nbsp;
                                  </span>
                                  <span className="text-md">
                                    {formatTo12Hour(
                                      new Date(
                                        `${exam?.booked_date} ${exam?.booked_time}`
                                      )
                                    )}
                                  </span>
                                </div>
                              ) : (
                                <div className="mt-2 md:mt-0">
                                  <span className="text-md">
                                    End Date:&nbsp;
                                  </span>
                                  <span className="text-md">
                                    {`${new Date(
                                      exam?.end_date
                                    ).toLocaleDateString("en-ng")}`}
                                    &nbsp;
                                    {formatTo12Hour(
                                      new Date(
                                        `${exam?.end_date} ${exam?.end_time}`
                                      )
                                    )}
                                  </span>
                                </div>
                              )}
                            </li>
                            {exam?.booking_status === 1 ? (
                              <li className="flex justify-between border-b border-gray-300 py-4 px-4">
                                <div>
                                  <span className="text-md">Venue:&nbsp;</span>
                                  <span className="text-md">{exam?.venue}</span>
                                </div>
                                <div>
                                  <span className="text-md">State:&nbsp;</span>
                                  <span className="text-md">{exam?.state}</span>
                                </div>
                              </li>
                            ) : null}
                            <li className="flex justify-between border-b border-gray-300 py-4 px-4">
                              <div>
                                <span className="text-md">
                                  Exam Status:&nbsp;
                                </span>
                                <span className="text-md">
                                  {!exam?.group_is_locked ? (
                                    <span className="bi bi-check-circle md:ml-8 text-primary text-2xl"></span>
                                  ) : (
                                    <span className="bi bi-x-octagon md:ml-8 text-red-500 text-2xl"></span>
                                  )}
                                </span>
                              </div>
                              <div>
                                <span className="text-md">
                                  Booking Status:&nbsp;
                                </span>
                                <span className="text-md">
                                  {exam?.require_booking ? (
                                    exam?.booking_status === 1 ? (
                                      <span className="bi bi-check-circle md:ml-8 text-primary text-2xl"></span>
                                    ) : (
                                      <span className="bi bi-x-octagon md:ml-8 text-red-500 text-2xl"></span>
                                    )
                                  ) : (
                                    <span className="text-sm">
                                      Not Required
                                    </span>
                                  )}
                                </span>
                              </div>
                            </li>
                            <li className="flex justify-between mt-3 py-4 px-4">
                              <span className="text-md">Action:&nbsp;</span>
                              <span className="text-md">
                                {exam?.group_is_locked ? (
                                  <>
                                    {exam?.require_booking ||
                                    exam?.booking_status === 1 ? (
                                      <button
                                        onClick={() => handleClickExam(exam)}
                                        className="py-2 bg-blue-500 mr-5  text-white  rounded-full text-md px-5"
                                      >
                                        {exam?.booking_status === 1
                                          ? "Update Booking"
                                          : "Book Exam"}
                                      </button>
                                    ) : null}
                                    <span className="capitalize text-sm text-red-500">
                                      Exam is currently Locked
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {exam?.require_booking ? (
                                      <button
                                        onClick={() => handleClickExam(exam)}
                                        className="py-2 bg-blue-500 mr-5  text-white  rounded-full text-md px-5"
                                      >
                                        {exam?.booking_status === 1
                                          ? "Update Booking"
                                          : "Book Exam"}
                                      </button>
                                    ) : null}
                                    {/* (exam?.booking_status === 1 || !exam?.require_booking) ?
                                 <Link
                                    onClick={() => handleUserId(exam)}
                                    to={`/candidate/assessments/candidate-exams/${exam?.group_id}`}
                                    className="py-3 bg-customGreen text-white rounded-full text-md px-8"
                                  >
                                    &nbsp;Start Exam 
                            </Link> : null */}
                                    {exam?.booking_status === 1 ||
                                    !exam?.require_booking ? (
                                      <button
                                        onClick={() => handleUserId(exam)}
                                        className="py-3 bg-customGreen text-white rounded-full text-md px-8"
                                      >
                                        &nbsp;
                                        {exam?.assessments?.length
                                          ? exam?.assessments[0]
                                              ?.assessment_lock_status === 0
                                            ? "Unlock Exam"
                                            : "Start Exam"
                                          : "Start Exam"}
                                      </button>
                                    ) : null}
                                  </>
                                )}
                              </span>
                            </li>
                          </ul>
                        );
                      }
                    )
                  : null}
              </div>
            </>
          ) : (
            <div className="text-center">
              <h5 className="bi bi-book text-center text-[60px] mt-8"></h5>
              <p className="text-center text-xl">
                You don't have any upcoming exam yet.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default UpcommingAssessment;
